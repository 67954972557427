import React from "react";
import Navbar from "./components/Navbar";
import { BrowserRouter, Route } from "react-router-dom";

// Components, Item Pages
// import Persona from "./components/ItemPages/Persona";
import Work from "./components/ItemPages/Work";
import Contact from "./components/ItemPages/Contact";
// import Moba from "./components/ItemPages/Moba";
// import Mbrot from "./components/ItemPages/Mbrot";
import Systems from "./components/ItemPages/Systems";
import EBPF from "./components/ItemPages/Ebpf";
import PPA from "./components/ItemPages/PPA";
import PII from "./components/ItemPages/PII";
import Sbudnic from "./components/ItemPages/Sbudnic";
import Resume from "./components/Resume";

import {
  sbudnicN,
  // persona3,
  pii,
  // moba15,
  ebpf3,
  ppa,
  k9db,
} from "./components/images";

const allItems = [
  {
    id: 0,
    title: "eBPF Systems Observability",
    subtitle: "Building lightweight, performant observability tools for distributed systems.",
    category: "code, engineering",
    image: ebpf3,
    navLink: "/ebpf",
    component: EBPF,
    date: "2023-2024",
  },
  {
    id: 1,
    title: "GDPR-compliant database (OSDI'23)",
    subtitle: "Creating privacy-centric computer systems abstractions that achieve compliance with GDPR-like legislation by default.",
    category: "code, engineering",
    image: k9db,
    navLink: "/systems",
    component: Systems,
    date: "2023",
  },
  {
    id: 2,
    title: "Privacy preserving advertising",
    subtitle: "Designing cryptographic protocols for a privacy-preserving ad ecosystem.",
    category: "code, engineering",
    image: ppa,
    navLink: "/ppa",
    component: PPA,
    date: "2023",
  },
  {
    id: 3,
    title: "PII Detection using ML",
    subtitle: "Developing a machine learning architecture to detect and redact 60 types of Personally Identifiable Information (PII) in semi-structured data.",
    category: "code, engineering",
    image: pii,
    navLink: "/pii",
    component: PII,
    date: "2022",
  },
  {
    id: 4,
    title: "Cube Satellite SBUDNIC",
    subtitle: "Testing the limits of how quickly and efficiently a 3U CubeSat can be developed. Supported by NASA and the National Research Council of Italy (CNR).",
    category: "code, design, engineering",
    image: sbudnicN,
    navLink: "/sbudnic",
    component: Sbudnic,
    date: "2022",
  },
  // {
  //   id: 5,
  //   title: "Mbrot",
  //   subtitle: "Generating custom fractal images with the Mandelbrot set",
  //   category: "code",
  //   image: mbrot1,
  //   navLink: "/mbrot",
  //   component: Mbrot,
  //   date: "2019",
  // },

  // {
  //   id: 6,
  //   title: "Moba",
  //   subtitle:
  //     "Redesigning a webpage for the Museum of Bad Art to be more responsive and accessible",
  //   category: "design",
  //   image: moba15,
  //   navLink: "/moba",
  //   component: Moba,
  //   date: "2019",
  // },
  // {
  //   id: 7,
  //   title: "Personas",
  //   subtitle:
  //     "Analyzing user interactions with a food ordering app at Brown University",
  //   category: "design",
  //   image: persona3,
  //   navLink: "/persona",
  //   component: Persona,
  //   date: "2019",
  // },
  // {
  //   id: 5,
  //   title: "A/B Testing",
  //   subtitle:
  //     "Designing and statistically testing two versions of an online store for cacti",
  //   category: "code, design",
  //   image: ab1,
  //   navLink: "/abtesting",
  //   component: ABTesting,
  //   date: "2019",
  // },
  // {
  //   id: 6,
  //   title: "Mesh",
  //   subtitle:
  //     "Rebranding a startup and designing a prototype product from scratch",
  //   category: "design",
  //   image: meshCover,
  //   navLink: "/mesh",
  //   component: Mesh,
  //   date: "2019",
  // },
  // {
  //   id: 9,
  //   title: "One Stop Song Shop",
  //   subtitle: "Practicing react by reimagining an online store for songs",
  //   category: "design,code",
  //   // image: dev1,
  //   image: eleven,
  //   navLink: "/dev",
  //   component: Dev,
  //   date: "2019",
  // },
  // {
  //   id: 10,
  //   title: "Spectrum",
  //   subtitle: "Creating custom yearbook cover designs",
  //   category: "design,photography",
  //   image: yearbook2,
  //   navLink: "/spectrum",
  //   component: Spectrum,
  //   date: "2017",
  // },

  // {
  //   id: 6,
  //   title: "Winterline",
  //   subtitle: "Gap Year Journalism",
  //   category: "journalism",
  //   image: five,
  //   navLink: "/winterline",
  //   component: Winterline,
  // },
  // {
  //   id: 7,
  //   title: "Bangkok Fish Market",
  //   subtitle: "Photography from Bangkok",
  //   category: "photography",
  //   image: six,
  //   navLink: "/bangkok",
  //   component: Bangkok,
  // },
];

const navItems = [
  // { id: 1, navItem: "home" },
  { id: 2, navItem: "work" },
  { id: 4, navItem: "resume" },
  { id: 3, navItem: "contact" },
];

const App = () => (
  <BrowserRouter>
    <Navbar navItems={navItems} />
    {allItems.map((item) => (
      <Route key={item.id} id={item.id} path={item.navLink} component={item.component} />
    ))}

    <Route exact path="/" component={Work} />
    <Route exact path="/work" component={Work} />
    {/* <Route exact path="/work" component={MyWork} /> */}
    <Route exact path="/contact" component={Contact} />
    <Route exact path="/resume" component={Resume} />
  </BrowserRouter>
);

export default App;
export { allItems };
